<template>
  <v-row class="fill-height">
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-col>
      <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Hoy
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="dialog"
            max-width="800px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                class="mx-2"
                v-on="on"
              >
                CREAR EVENTO
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ (editedItem.id !== null) ? 'EDITAR' : 'CREAR' }} EVENTO</span>
              </v-card-title>

              <v-card-text>
                <v-form
                  ref="form"
                >
                  <v-container>
                    <input
                      v-model="editedItem.id"
                      type="hidden"
                    >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.titulo"
                          label="Título"
                          :rules="[v => !!v || 'Campo requerido']"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="editedItem.descripcion"
                          label="Descripción"
                          :rules="[v => !!v || 'Campo requerido']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-datetime-picker
                          v-model="editedItem.inicio"
                          label="Inicio"
                          :rules="[v => !!v || 'Campo requerido']"
                          date-format="dd/MM/yyyy"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="6"
                      >
                        <v-datetime-picker
                          v-model="editedItem.fin"
                          label="Fin"
                          :rules="[v => !!v || 'Campo requerido']"
                          date-format="dd/MM/yyyy"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="#F6F6F6"
                  style="color:black;"
                  @click="close"
                >
                  {{ $t("close") }}
                </v-btn>
                <v-btn
                  class="mr-4"
                  color="primary"
                  :loading="loading"
                  @click="save"
                >
                  {{ $t("save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ type.value }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="changeDisplayRange('day')">
                <v-list-item-title>Día</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeDisplayRange('4day')">
                <v-list-item-title>4 días</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeDisplayRange('week')">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="changeDisplayRange('month')">
                <v-list-item-title>Mes</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                outlined
                color="grey darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <span>{{ eventOwner === 'current' ? 'Personal' : 'Todos' }}</span>
                <v-icon right>
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="eventOwner = 'current'; initialize()">
                <v-list-item-title>Personal</v-list-item-title>
              </v-list-item>
              <v-list-item @click="eventOwner = 'all'; initialize()">
                <v-list-item-title>Todos</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          locale="es"
          :dark="true"
          :events.sync="events"
          :event-color="getEventColor"
          :type="type.option"
          :first-interval="intervals.first"
          :interval-minutes="intervals.minutes"
          :interval-count="intervals.count"
          :interval-height="intervals.height"
          @change="handleCalendarChange"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
        />
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn
                icon
                @click="editAgenda(selectedEvent)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title
                style="text-transform: uppercase;"
                v-html="selectedEvent.name"
              />
              <v-spacer />
              <v-btn
                icon
                @click="deleteAgenda(selectedEvent)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              Descripción: <span v-html="selectedEvent.descripcion" /><br><br>
              Inicio: <span v-html="selectedEvent.inicio" /><br>
              Fin: <span v-html="selectedEvent.fin" /><br><br>
              Usuario: <span v-html="selectedEvent.usuario" /><br>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                CERRAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
  import Vue from 'vue'
  export default {
    name: 'AgendaAdmin',
    data: () => ({
      focus: '',
      type: {
        option: 'month',
        value: 'Mes',
      },
      eventOwner: 'current',
      dateRangeOnDisplay: {
        start: null,
        end: null,
      },
      intervals: {
        first: 16,
        minutes: 30,
        count: 20,
        height: 48,
      },
      menu: false,
      menu1: false,
      dialog: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      editedItem: {
        id: null,
        inicio: '',
        fin: '',
        start: null,
        end: null,
        descripcion: '',
        titulo: '',
      },
    }),
    computed: {
      loading: {
        get () {
          return this.$store.state.agenda.loading
        },
      },
    },
    mounted () {
      this.$refs.calendar.checkChange()
    },
    methods: {
      initialize () {
        const params = {
          start_date: this.dateRangeOnDisplay.start,
          end_date: this.dateRangeOnDisplay.end,
          event_owner: this.eventOwner,
        }
        this.$store.dispatch('agenda/getAgendas', { params })
          .then((response) => {
            // console.log(response)
            this.updateEvents(response.data)
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
          })
      },
      formatDate (date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      changeDisplayRange (val) {
        switch (val) {
          case 'day':
            this.type.option = 'day'
            this.type.value = 'Día'
            break
          case '4day':
            this.type.option = '4day'
            this.type.value = '4 días'
            break
          case 'week':
            this.type.option = 'week'
            this.type.value = 'Semana'
            break
          default:
            this.type.option = 'month'
            this.type.value = 'Mes'
            break
        }
      },
      viewDay ({ date }) {
        this.focus = date
        this.type.option = 'day'
        this.type.value = 'Día'
      },
      handleCalendarChange (value) {
        this.dateRangeOnDisplay.start = value.start.date
        this.dateRangeOnDisplay.end = value.end.date
        this.initialize()
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = ''
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          setTimeout(() => {
            this.selectedOpen = true
          }, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        // nativeEvent.stopPropagation()
      },
      updateEvents (eventos) {
        const events = []

        for (let i = 0; i < eventos.length; i++) {
          const first = String(eventos[i].inicio.date).substring(0, 16)
          const second = String(eventos[i].fin.date).substring(0, 16)

          events.push({
            id: eventos[i].id,
            name: eventos[i].titulo,
            titulo: eventos[i].titulo,
            descripcion: eventos[i].descripcion,
            inicio: this.formatDate(String(eventos[i].inicio.date).substring(0, 10)) + String(eventos[i].inicio.date).substring(10, 16),
            fin: this.formatDate(String(eventos[i].fin.date).substring(0, 10)) + String(eventos[i].fin.date).substring(10, 16),
            start: first,
            end: second,
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            timed: true,
            usuario: eventos[i].usuario.nombre + ' ' + eventos[i].usuario.apellido,
          })
        }

        this.events = events
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      close () {
        this.$refs.form.reset()
        this.dialog = false
        this.editedItem.id = null
        this.editedItem.descripcion = null
        this.editedItem.titulo = null
        this.editedItem.inicio = null
        this.editedItem.fin = null
      },
      toTime (fecha) {
        return new Date(Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), fecha.getHours(), fecha.getMinutes()))
      },
      toTime2 (fecha) {
        return Date.UTC(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), fecha.getHours(), fecha.getMinutes())
      },
      save () {
        if (this.$refs.form.validate()) {
          const data = {
            id: this.editedItem.id,
            titulo: this.editedItem.titulo,
            descripcion: this.editedItem.descripcion,
            inicio: this.toTime(this.editedItem.inicio),
            fin: this.toTime(this.editedItem.fin),
          }
          if (this.editedItem.id !== null) {
            this.$store.dispatch('agenda/updateAgenda', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                this.close()
                this.initialize()
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          } else {
            this.$store.dispatch('agenda/addAgenda', data)
              .then((response) => {
                Vue.notify({
                  group: 'loggedIn',
                  text: response.data.success,
                  type: 'teal accent-3',
                  duration: 5000,
                })
                this.close()
                this.initialize()
              })
              .catch(err => {
                // eslint-disable-next-line
                console.log(err)
                Vue.notify({
                  group: 'loggedIn',
                  text: 'No se pudo guardar en este momento',
                  type: 'red accent-2',
                  duration: 5000,
                })
              },
              )
          }
        }

        // AgendaApi.updateAgenda(data)
        //   .then(response => {
        //     this.time = response.inicio_dia
        //     this.time1 = response.fin_dia
        //     this.time2 = response.inicio_tarde
        //     this.time3 = response.fin_tarde
        //     this.text = 'Registro modificado correctamente'
        //     this.snackbar = true
        //     this.colorSnak = 'success'
        //     this.close()
        //   })
        //   .catch(error => {
        //     console.log(error)
        //     this.text = 'El registro no puede ser modificado en este momento'
        //     this.colorSnak = 'error'
        //     this.snackbar = true
        //   })
      },
      deleteAgenda (item) {
        this.$confirm('Está seguro que desea eliminar el evento?', {
          buttonTrueText: 'Si',
          buttonFalseText: 'No',
        })
          .then(res => {
            if (res) {
              this.$store.dispatch('agenda/deleteAgenda', item.id)
                .then((response) => {
                  Vue.notify({
                    group: 'loggedIn',
                    text: response.data.success,
                    type: 'teal accent-3',
                    duration: 5000,
                  })
                  this.initialize()
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err)
                  Vue.notify({
                    group: 'loggedIn',
                    text: 'No se pudo eliminar en este momento',
                    type: 'red accent-2',
                    duration: 5000,
                  })
                },
                )
            }
          })
      },
      editAgenda (item) {
        // const data = {
        //   id: item.id,
        //   titulo: item.name,
        //   descripcion: item.descripcion,
        //   inicio: item.inicio,
        //   fin: item.fin,
        // }
        // this.editedItem = Object.assign({}, item)
        this.$store.dispatch('agenda/getEventoById', item.id)
          .then((response) => {
            // this.editedItem = Object.assign({}, response.data)
            this.editedItem.id = response.data.id
            this.editedItem.descripcion = response.data.descripcion
            this.editedItem.titulo = response.data.titulo
            this.editedItem.inicio = new Date(response.data.inicio.date)
            this.editedItem.fin = new Date(response.data.fin.date)
            this.dialog = true
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
          },
          )
        // this.dialog = true
      },
    },
  }
</script>
